<template>
  <div class="authentication-bg min-vh-100">
    <div class="bg-overlay bg-black"></div>

    <div class="container">
      <div class="d-flex flex-column min-vh-100 px-3">
        <div class="row justify-content-center my-auto">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="text-center py-5">

              <!-- // Logo -->
              <div class="mb-2 mb-md-4">
                <div class="d-block auth-logo">
                  <img src="@/assets/images/spocha-logo.png" alt=""
                       height="136" class="auth-logo-dark" />
                  <img src="@/assets/images/spocha-logo.png" alt=""
                       height="136" class="auth-logo-light" />
                </div>
              </div>

              <div class="mb-4">
                <div class="mb-1">
                  <img src="@/assets/images/spocha-text-logo.png" alt=""
                       height="56" class="auth-logo-dark" />
                </div>
                <div class="text-white font-size-24">歓迎致します!</div>
              </div>

              <!-- Form Start -->
              <form @submit.prevent="tryToLogIn">

                <div class="form-floating form-floating-custom mb-3">
                  <input type="text"
                         v-model="userId"
                         class="form-control"
                         id="sign-in-input-user-id"
                         placeholder="Enter User ID"
                         :class="{ 'is-invalid': submitted && $v.userId.$error }" />
                  <label for="sign-in-input-user-id">{{ $t('account.id') }}</label>
                  <div class="form-floating-icon">
                    <i class="mdi mdi-email-outline"></i>
                  </div>
                  <div v-if="submitted && $v.userId.$error" class="invalid-feedback">
                    <span v-if="!$v.userId.required">{{ $t('validation.emailIdIsRequired') }}</span>
                    <span v-if="!$v.userId.email">{{ $t('validation.enterValidEmail') }}</span>
                  </div>
                </div>

                <div class="form-floating form-floating-custom mb-3">
                  <input type="password"
                         v-model="userPwd"
                         class="form-control"
                         id="sign-in-input-user-pwd"
                         placeholder="Enter User Password"
                         :class="{ 'is-invalid': submitted && $v.userPwd.$error }" />
                  <label for="sign-in-input-user-pwd">{{ $t('common.password') }}</label>
                  <div class="form-floating-icon">
                    <i class="mdi mdi-lock-outline"></i>
                  </div>
                  <div v-if="submitted && !$v.userPwd.required" class="invalid-feedback">
                    <span>{{ $t('validation.pwdIsRequired') }}</span>
                  </div>
                </div>

                <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-check form-check-info font-size-16">
                      <input class="form-check-input"
                             type="checkbox"
                             id="remember-check"
                             v-model="isCheckedRememberId" />
                      <label class="form-check-label font-size-14 text-white-50" for="remember-check">{{ $t('account.rememberId') }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6 text-end">
                    <router-link to="/find-password"
                                 class="text-white-50 text-decoration-underline">{{ $t('account.findPwd') }}</router-link>
                  </div>
                </div>

                <div class="mt-3">
                  <button class="btn btn-info w-100" type="submit">{{ $t('account.signIn') }}</button>
                </div>

                <div class="row mt-5 mb-5 text-center">
                  <router-link to="/signup"
                               class="fw-medium text-decoration-underline font-size-16"
                               style="color: #A0F519">{{ $t('account.memberJoin') }}</router-link>
                </div>

                <div class="row text-center text-white-50">
                  <span>{{ $t('account.systemContact') }} : 0120-322-302</span>
                </div>


<!--                <div class="row text-center">
                  <b-button variant="outline-primary" @click="googleLogin"><i class="bx bxl-google"></i>Log in with Google</b-button>
                </div>-->

              </form>
              <!-- Form End -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import { loadingMethods } from '@/state/helpers';
import { required, email } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      userId: null,
      userPwd: null,
      submitted: false,
      isAuthError: false,
      authError: null,
      isCheckedRememberId: null
    }
  },
  validations: {
    userId: { required, email },
    userPwd: { required }
  },
  created() {
    const vm = this;
    vm.checkSavedId();
  },
  methods: {
    ...loadingMethods,
    googleLogin() {
      const vm = this;
      vm.href = window.location.href.substr(0, window.location.href.lastIndexOf('/'));
      vm.hostName = vm.href + '/oauth2/authorize/google';
      vm.redirectUri = vm.href + '/oauth2/redirect';
      vm.googleApiUrl = vm.hostName + '?redirect_uri=' + vm.redirectUri;
      window.location.href = vm.googleApiUrl;
    },
    tryToLogIn() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        vm.showOverlay();

        this.$_app.signIn(vm.userId, vm.userPwd).then(res => {
          vm.hideOverlay();
          vm.setRememberLoginInfo();
          window.location.href = '/';
        }).catch(err => {
          vm.hideOverlay();
          let errString = err.toString();
          let errType = '';

          if (errString.indexOf('400') !== -1) {
            if (err.response.data.message === 'Bad Credentials') {
              errType = i18n.t('account.err400-1');
            } else if (err.response.data.message === 'Unauthorized') {
              errType = i18n.t('account.err400-2');
            }
          } else if (errString.indexOf('500') !== -1) {
            errType = i18n.t('account.err500');
          } else {
            errType = err;
          }

          vm.isAuthError = true;
          vm.authError = errType;
        });
      }
    },
    setRememberLoginInfo() {
      const vm = this;
      const userId = vm.userId;

      if (vm.isCheckedRememberId) {
        localStorage.setItem('savedId', userId);
      } else {
        localStorage.removeItem('savedId');
      }
    },
    checkSavedId() {
      const vm = this;
      const savedId = localStorage.getItem('savedId');

      if (savedId != null) {
        vm.isCheckedRememberId = true;
        vm.userId = savedId;
      } else {
        vm.isCheckedRememberId = false;
      }
    }
  }
}
</script>
